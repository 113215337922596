<template>
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 299.4 380.99"
  >
    <circle cx="149.7" cy="149.7" r="149.7" :fill="fill" />
    <path
      d="M152.47 277.22c13.59 0 25.62-8.78 30.49-22-1-1-1.91-1.93-2.78-2.92-3.82 12.3-14.78 21.18-27.72 21.18s-23.9-8.89-27.72-21.18c-.87 1-1.79 2-2.78 2.92 4.89 13.24 16.92 22 30.51 22"
      fill="#fff"
      fill-rule="evenodd"
    />
    <path
      d="M180.19 250.94c-8.69-9.69-11.49-21-9.91-34.71h-35.62c1.58 13.71-1.22 25-9.91 34.71a28.9 28.9 0 0055.44 0"
      fill="#e46b6b"
      fill-rule="evenodd"
    />
    <path
      d="M180.19 251.22c-8.67-9.77-11.46-21.18-9.88-35h-35.64c1.58 13.83-1.22 25.25-9.92 35l.11.3a92.29 92.29 0 0026.67 3.51c11.42 0 20.85-1.36 28.65-3.81"
      fill="#e05b5b"
      fill-rule="evenodd"
    />
    <path
      d="M85.42 189.51c-6-68.1 90.59-50 92.54-105.43 0 0 11.38 23.92 0 40.1 0 0 13.65.7 15.93-8.44 0 0 5.31 15.08 3 20.2 0 0 6.83-.5 7.59-10.35 1 9.09 3.86 56.41 7.59 63.91 3.18-26.46 51.18-96.66-21.28-123.36 0 0-51.66-44.69-105.45 6.67-23.93 21.31-38.75 55.18 0 116.7"
      fill="#3f2e2d"
      fill-rule="evenodd"
    />
    <path
      d="M85.31 72.5s-17 13.42-22.54 36.62c5.59-9.27 24.73-37.58 54.68-46.47 36-10.69 72.84 3.27 72.84 3.27s-51.43-44.1-105 6.58M229.66 124.75c2.13-20.72-2.65-48-38.38-58.21 0 0 39.47 24.39 38.38 58.21"
      fill="#5d4546"
      fill-rule="evenodd"
    />
    <path
      d="M98.91 167.87c6.74 12.73 3.92 27.32-6.31 32.59s-24-.76-30.72-13.49-3.92-27.32 6.31-32.59 24 .76 30.72 13.49"
      fill="#e6746f"
      fill-rule="evenodd"
    />
    <path
      d="M94.26 191.28c0-.51-.15-1-.2-1.46.08.36.13.66.16.9a17.83 17.83 0 00-1.15-6.34c-5.49-21.43-26.54-23.59-26.54-23.59 10.66 6.13 17.31 12.89 21.43 18.49-7.56-2.94-19.26 3.28-19.26 3.28 13.87-3.5 20.19-.6 23.08 2.76a28.79 28.79 0 012.47 6"
      fill="#e04f4c"
      fill-rule="evenodd"
    />
    <path
      d="M89.53 196.83s-15.7-44.27 2.17-69.3l10.83 32.84-13 36.46"
      fill="#3f2e2d"
      fill-rule="evenodd"
    />
    <path
      d="M200.53 167.87c-6.74 12.73-3.92 27.32 6.31 32.59s24-.76 30.72-13.49 3.92-27.32-6.31-32.59-24 .76-30.72 13.49"
      fill="#e6746f"
      fill-rule="evenodd"
    />
    <path
      d="M205.14 191.28c0-.51.14-1 .2-1.46a7 7 0 00-.15.9 17.83 17.83 0 011.15-6.34c5.49-21.43 26.53-23.59 26.53-23.59-10.66 6.13-17.31 12.89-21.43 18.49 7.56-2.94 19.26 3.28 19.26 3.28-13.87-3.5-20.18-.6-23.08 2.76a28.56 28.56 0 00-2.48 6"
      fill="#e04f4c"
      fill-rule="evenodd"
    />
    <path
      d="M207.37 202.37s19.52-11.93 8-66.53l-24.08 7.64 16.08 58.89"
      fill="#3f2e2d"
      fill-rule="evenodd"
    />
    <path
      d="M208.91 177.79l-4.3-53.09c-.76 10-7.47 10.67-7.47 10.67 2.26-5.18-3.33-20.19-3.33-20.19-2.26 9.25-15.43 8.54-15.43 8.54 11.32-16.36-.42-40.56-.42-40.56-1.51 27.75-22.65 38.42-58.89 52.44-27.06 10.47-32.23 35.49-33.11 47.5 1 33.12 10.73 63.61 62.65 63.61 45.24 0 58.26-21.52 61.65-47.7a57.49 57.49 0 00-1.35-21.22"
      fill="#efa07a"
      fill-rule="evenodd"
    />
    <!-- eyes -->
    <ellipse cx="180" cy="164" rx="4" ref="leftEye" fill="#150d18" ry="8" />
    <ellipse cx="121" cy="164" rx="4" ref="rightEye" fill="#150d18" ry="8" />
    <path
      d="M105.35 152.47s22.18-7 33.27-6.42c0 0-1.87-12.11-15.11-10-15.71 2.55-18.16 16.38-18.16 16.38M194.05 146.93s-22.51-4.3-33.27-2.42c0 0 .37-12 13.56-11.42 15.65.67 19.71 13.84 19.71 13.84"
      fill="#3f2e2d"
      fill-rule="evenodd"
    />
    <path
      d="M118.05 191.28a1.56 1.56 0 01-.7-.16 1.62 1.62 0 01-.75-2.17l5.09-10.58a1.612 1.612 0 112.9 1.41l-5.06 10.59a1.61 1.61 0 01-1.45.92M106.96 191.28a1.59 1.59 0 01-.7-.16 1.62 1.62 0 01-.75-2.17l5.09-10.58a1.612 1.612 0 112.9 1.41l-5.09 10.59a1.6 1.6 0 01-1.45.92M184.13 191.28a1.62 1.62 0 01-1.46-.92l-5.09-10.61a1.64 1.64 0 01.75-2.17 1.6 1.6 0 012.2.79l5 10.58a1.63 1.63 0 01-.75 2.17 1.55 1.55 0 01-.69.16M192.44 191.28a1.62 1.62 0 01-1.46-.92l-5.09-10.61a1.63 1.63 0 01.75-2.17 1.61 1.61 0 012.15.76l5.09 10.61a1.63 1.63 0 01-.75 2.17 1.49 1.49 0 01-.69.16M153.42 214.37c-16.91 0-27.42-7.6-28-8a1.65 1.65 0 01-.31-2.42 1.92 1.92 0 012.58-.29c.15.12 15.79 11.34 39.07 5.6a1.86 1.86 0 012.24 1.23 1.73 1.73 0 01-1.31 2.11 59.49 59.49 0 01-14.3 1.78"
      fill="#e04f4c"
      fill-rule="evenodd"
    />
    <path
      fill="#f0ac8a"
      fill-rule="evenodd"
      d="M178.84 84.52l-.04-.09-.02.24.06-.15"
    />
    <path
      d="M149.62 234.01c-32.41 0-49.4-10.59-58.13-26.09 5.74 22.14 20.78 38.81 58.11 38.81 38.11 0 52.85-16.16 58.32-37.56-8.61 14.76-25.51 24.84-58.3 24.84"
      fill="#efa987"
      fill-rule="evenodd"
    />
    <path
      d="M64.12 245.07a9.71 9.71 0 008.88-2.42c1.49 7.39-4.09 13.1-9.94 16.46 6.2 3.28 12.47-.78 16.06-5.88 0 9.84-5.22 18.66-12.77 24.67 5.26 3.6 10.85-1 14.78-4.45-3.54 9.06-7.67 18.49-15.29 24.88 4.47 2.68 9.7.39 13.28-2.68-.07 11.22-5.73 21.29-14.49 28.14 6.12 6.2 14.43 1.46 19.44-3.65-1 8-4.48 17.45-13.18 19.6 4.76 2.6 13.76 3.49 21.44-3.69.36 6-1.58 12.18-6.61 15.83 6.57.73 14.61-.65 18.21-6.84-.07 5.36-.58 11.94-5.53 15.14 5.18 6.4 13.34 3.58 17.17-2.62.25 4.67-.87 10-5.52 12 4.62 3 14.52 4.54 23.71-3.12 1.62 7 6.34 16.35 15 14.28-2-6-3.75-13.1 0-18.8 4 2.36 6.38 7.27 3.77 11.57 6-.31 10.08-5.28 11.14-10.92 4.67 6 12.69 9.18 19.88 5.93-3.54-4-6-8.19-3.93-13.74a28.78 28.78 0 0023.06 3.2c-2.1-5.46-4.78-12.15-1.44-17.62 3.22 2.95 6.47 5.78 7.07 10.54a15.94 15.94 0 004.56-13.44c6.14 3.9 14 1.94 19.76-1.66-6.81-3.54-14.41-9.3-14-17.81 3.69 1.15 6.3 3.22 6.9 7.29 2-10.27 2.53-21.7-2.94-31 2.5 1.07 6.88 4.91 7 9.8.52-11.7.25-25.37-8.13-34.48 1.35-.86 2.86-.24 4.84 2 1-9.39 1.23-20.08-4.87-27.91 4.11.22 9.37-1.12 11.22-5-26.73-12.64-.76-51.4-24.21-66.15 1.9 12.57-3.08 34.42-20 26.9-12.77-5.15-26.91-26.05-39.39-8.97-22.91-24.86-36.57 29-55.38 7.61-7.9-9.57-4.65-31.48-8.67-23.29-19 23-4.86 57.79-29.64 64.37v.23c2.51 2.49 5.22 4.93 8.76 5.7zm61.7-31.19c6-2.67 13.37-.68 18.7-5.48 2.06-1.61 4.43-2.89 6.9-1.33 6.21 5.44 15.14 3.92 22 7.83 4 5.62-14.88 10.52-19.06 10.26-6.52.89-36.64-.64-28.54-11.28z"
      fill="#3f2e2d"
    />
    <path
      d="M154.97 182.46c0 4.78-3.1 8.65-6.93 8.65s-6.93-3.87-6.93-8.65 3.1-10.75 6.93-10.75 6.93 6 6.93 10.75"
      fill="#e04f4c"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    fill: {
      type: String,
      default: '#ffd25f'
    }
  },
  mounted() {
    this.blink()
  },
  methods: {
    blink() {
      const { leftEye, rightEye } = this.$refs

      setInterval(() => {
        leftEye.classList.add('blink')
        rightEye.classList.add('blink')
        setTimeout(() => {
          leftEye.classList.remove('blink')
          rightEye.classList.remove('blink')
        }, 500)
      }, 5000)
    }
  }
}
</script>
